@import '../../../app/components/colours.scss';

.games {
  width: 90%;
  padding: 0;
  margin: 0 auto;
}

@media screen and (max-width: 400px) {
  .games {
    width: 90%;
  }
}
