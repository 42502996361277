.faq {
  margin: 0 auto;

  .qa-list {
    list-style: none;
    padding: 0;
  
    .qa {
      margin: 40px 0;
      text-align: left;
  
      .q {
        font-size: 26px;
        margin-bottom: 20px;
        text-align: left;
      }

      .a {
        font-size: 16px;
        text-align: left;
      }
    }
  }

  a {
    color: red;
  }
}
