.theme-picker {
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: white;

  .theme-button {
    display: inline-block;
    border: 2px solid white;
    border-radius:50%;
    width: 1.5em;
    height: 1.5em;
    max-width: 30px;
    max-height: 30px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 800px) {
  .theme-picker {
    display: none;
  }
}