$colours: (
  // system
  white: #ffffff,
  black: #000000,

  // bright
  marzipan: #f8e9a1,
  radical-red: #f73c6c,
  regent-st-blue: #a8d0e6,
  chambray: #374785,
  cloud-burst: #24305e,
  athens-grey: #f7f7f8,

  // dark
  heliotrope: #bb86fc,
  purple: #3700b3,
  robins-egg-blue: #03dac5,
  cod-gray: #121212,
  chestnut-rose: #cf6679,

  // neon
  wine-berry: #501f3a,
  hibiscus: #cb2d6f,
  silver: #cccccc,
  mountain-meadow: #14a098,
  firefly: #0f292f,

  // fresh
  catalina-blue: #05386b,
  lochinvar: #379683,
  pastel-green: #5cdb95,
  algae-green: #8ee4af,
  loafer: #edf5e1
)
