@import 'colours.scss';
@font-face {
  font-family: octet;
  src: url('../../../assets/font/octet.otf')
 }

html {
  @include generateThemeVariables;
  height: 100%;
}

h1 {
  font-size: 84px;
  margin: 0;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

hr {
  width: 50%;
  border: 0;
  border-top: 1px solid;
  @include theme(border-color, onBackground);
}

h1 h2 h3 h4 h5 {
  @include theme(color, onBackground);
}

a {
  @include theme(color, primary);
}

div {
  text-align: center;
}

body {
  font-family: octet;
  font-size: 20px;
  line-height: 1.42857143;
  border: 0;
  letter-spacing: 0.05em;
  @include theme(color, onBackground);
  min-width: 400px;
}

.App {
  display: flex; 
  flex-direction:column; 
  min-height: 100vh;
  @include theme(background-color, background);

  .content {
    margin: 30px auto;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 64px;
  }
  .App {
    .content {
      width: 100%;
    }
  }
}
