@import '../../app/components/colours.scss';

.nav-bar {
  width: 100%;
  padding: 10px 0;

  a{ 
    text-decoration: none;
  }

  .nav-bar-content {
    display: relative;
    margin: 0 auto;
    justify-content: space-between;
    width: 50%;
  }

  .nav-bar-brand {
    display: flex;
    justify-content: right;
    align-content: right;
    flex-direction: column;

    img {
      width: 32px;
    }
  }

  ul {
    padding: 0;
    display: inline-block;
    list-style: none;

    li{
      display: inline-block;
      min-width: 100px;

      .link {
        font-size: 24px;
        font-weight: 700;

        &:hover {
          @include theme(color, secondary);
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .nav-bar {
    .nav-bar-brand {
      display: none;
    }

    .nav-bar-content {
      display: inline-block
    }
  }
}
