@import '../../app/components/colours.scss';

.contact {
  .faq-notice {
    margin: 50px auto;
  }

  .socials {
    margin: 40px 30px;
    display: inline-block;
    max-width: 900px;

    .social {
      display: inline-block;
      margin: 10px 15px;
      width: 150px;
      height: 150px;
      border: 5px solid;
      border-radius: 10%;

      a {
        width: 100%;
        height: 100%;
      }

      svg {
        position: relative;
        top: 25%;
        width: 75px;
        height: 75px;
        vertical-align: middle;
        @include theme(fill, primary);

        path{
          fill: theme(background-color, secondary) !important;
        }

        circle{
          fill: theme(background-color, secondary) !important;
        }
      }

      &:hover {
        @include theme(background-color, primary);

        svg{
          @include theme(fill, background);
        }
      }
    }
  }
}
