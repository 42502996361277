.landing {
    .landing-about-info{
        margin: 0;
        position: absolute;
        top: 40%;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    
        h1 {
            font-size: 60px;
        }
    }
}
