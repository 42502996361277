@import '../../../app/components/colours.scss';

.game {
  position: relative;
  margin: 30px 0;
  border: 5px solid;
  padding: 0 auto;
  text-align: center;
  @include theme(border-color, onBackground);
  max-width: 900px;
  background-color: colour(cod-gray);

  a {
    display:inline-block;
  }

  .caption {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
    left: 0%;

    hr {
      margin-left: 0;
    }

    h4 {
      margin: 5px 0;
    }
  }
  
  img {
    display:inline-block;
    vertical-align: bottom;
    width: 100%;
  }

  &:hover {
    .caption {
      visibility: visible;
      z-index: 11111;
    }

    img {
      filter: blur(3px);
    }
  }
}
